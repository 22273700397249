import React, { useRef, useState } from 'react';
import { MdRefresh } from 'react-icons/md';
import classes from './quickpick-uploader.module.css';
import { authenticatedApiFetch } from '../actions/sdk';

const returnTrue = () => true;
const preventDefaults = event => event.preventDefault();

const makeDropFilesHandler = (fileHandler) => {
	return (event) => {
		event.preventDefault();

		if (event.dataTransfer.items) {
			const files = Array.from(event.dataTransfer.items).reduce((result, item) => {
				if (item.kind === 'file') result.push(item.getAsFile());
				return result;
			}, []);
			fileHandler(files);
		} else {
			fileHandler(Array.from(event.dataTransfer.files));
		}
	};
};

export default function QuickpicUploader(props) {
	const { invoiceNumber, afterUpload, ...rest } = props;

	const [uploading, setUploading] = useState(false);
	const [resultObject, setResultObject] = useState({});
	const hiddenUploadRef = useRef();

	/**
	 * @param {File} file
	 */
	const uploadPhoto = async (file) => {
		setUploading(true);

		let presignResult;
		let uploadResult;
		let followUpResult;

		presignResult = await authenticatedApiFetch('/v3/getquickpicpresignedupload', { query: { InvNmbr: invoiceNumber, FileName: file.name } });

		const apiData = await presignResult.parsed;

		if (presignResult.status === 200) {
			const s3Url = apiData.url;
			uploadResult = await fetch(s3Url, { method: 'PUT', body: file });
			followUpResult = await authenticatedApiFetch('/v3/afters3presignedupload', { query: { InvNmbr: invoiceNumber, FileName: file.name, ClientPlatform: 'web' } });
		}


		const result = {
			presign: { status: presignResult.status, data: apiData },
			upload: { status: uploadResult?.status, text: await uploadResult?.text() },
			follow: { status: followUpResult?.status, text: await followUpResult?.raw },
		};

		setResultObject(result);
		setUploading(false);

		const success = result.presign?.status === 200 && result.upload?.status < 400 && result.follow?.status === 200;

		if (success) {
			afterUpload?.(file);
		}
	};

	const onFileSelected = (file) => {
		uploadPhoto(file);
	};

	const onFileInputChange = (event) => {
		for (let file of Array.from(event.target.files)) {
			onFileSelected(file);
		}
	};

	const onDropFile = makeDropFilesHandler(
		files => {
			for (let file of files) {
				onFileSelected(file);
			}
		}
	);

	const selectPhotoForUpload = () => {
		hiddenUploadRef.current?.click();
	};

	let content;

	if (uploading) {
		content = (
			<>
				<MdRefresh className={classes.swirl} />
				<span>Uploading</span>
			</>
		);
	} else {
		content = (
			<>
				<span style={{ fontSize: 32 }}>+</span>
				<span>Drag/Click to Upload</span>
			</>
		);
	}

	return (
		<div onDragOver={preventDefaults} onDrop={onDropFile} onClick={selectPhotoForUpload} className={classes.container} {...rest}>
			{content}
			<div style={{ height: 0, width: 0, overflow: 'hidden' }}><input onChange={onFileInputChange} ref={hiddenUploadRef} type="file" /></div>
		</div>
	);
}
